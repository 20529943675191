import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";
import classNames from "classnames";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button"
import Headline from "../components/Headline"

export default class HomeAbout extends React.Component {
  render() {
    let { name, description } = this.props.content;
    let rowClasses = classNames(
      (typeof this.props.centerHorizontally === 'undefined' || this.props.centerHorizontally === true) ? "justify-content-center" : null,
      (this.props.centerVertically === true) ? "align-items-center" : null
    );
    let nameLines = name.split('. ').join('.<br/>');
    return (
      <div className="home-about">
        <Container className={classNames(this.props.className)}>
          <Row className={rowClasses}>
            <Col lg={7}>
              <Headline className="pt-3 text-center"><Link to="/about"><span dangerouslySetInnerHTML={{
                __html: nameLines
                }}
              /></Link></Headline>
              { (typeof this.props.showDescription === 'undefined' || this.props.showDescription === true) 
                  && description && description.childMarkdownRemark && <div dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
                }}
              />}
            </Col>
            <Col xs={12} className="text-center"><Button href="/about" variant="light" className="px-4 font-weight-bold text-dark">LEARN MORE</Button></Col>
          </Row>
        </Container>
      </div>
    )
  }
}

HomeAbout.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
  showDescription: PropTypes.bool,
  centerHorizontally: PropTypes.bool,
  centerVertically: PropTypes.bool,
}
