import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby";
import Col from 'react-bootstrap/Col';
import Section from "../components/Section"
import { getFeaturedItem, getFeaturedItems } from "../services/utility"
import HomeAbout from "../components/HomeAbout";
import Feature from "../components/Feature";
import Teaser from "../components/Teaser";
import Headline from "../components/Headline"

export default class Home extends React.Component {
  render() {
    let about = this.props.data.about;
    let top = getFeaturedItem(this.props.data.top);
    let featured = getFeaturedItems(this.props.data.featured);
    let more = getFeaturedItems(this.props.data.more);
    return ( 
      <Layout>
        <Seo />
        {about && <Section className="primary pb-0" useContainer={false}>
          <HomeAbout className="pb-5" content={about} centerVertically={true} useLink={true} />
        </Section> } 
        {top && <Section className="white" useContainer={false}>
          <Feature content={top} centerVertically={true} useLink={true} mediaRight={true} />
        </Section> }
        {featured && <Section className="secondary" useCol={false}>
          <Col xs={12}><Headline>Featured</Headline></Col>
          {featured.map((item) => {
            return (
              <Col md={4} key={"featured-" + item.slug}>
                <Teaser content={item} />
              </Col>
            );
          })}
        </Section> }
        {more && <Section className="white" useCol={false}>
        <Col xs={12}><Headline>More...</Headline></Col>
          {more.map((item) => {
            return (
              <Col md={4} key={"more-" + item.slug}>
                <Teaser content={item} />
              </Col>
            );
          })}
        </Section> }
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    about: contentfulVideoResource(slug: { eq: "about" }) {
      ...VideoResource
    }
    top: allContentfulFeatured(filter: {siteSection: {eq: "Home - Top"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    featured: allContentfulFeatured(filter: {siteSection: {eq: "Home - Must See"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }
    more: allContentfulFeatured(filter: {siteSection: {eq: "Home - More"}}) {
      edges {
        node {
          visibleDate
          hiddenDate
          items {
            ...Teaser
          }
        }
      }
    }    
  }
`
